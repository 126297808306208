(function () {
    'use strict';

    angular.module('wmsModule')
        .config(mainConfig);

    mainConfig.$inject = ['$stateProvider'];

    function mainConfig ($stateProvider) {
        $stateProvider
            .state('main', {
                parent: 'app',
                url: '/?showNavbar',
                data: {
                    'pageTitle': '首页'
                },
                params: {
                    'showNavbar': null
                },
                views: {
                    'content@': {
                        templateUrl: 'layouts/main/main.html',
                        controller: 'MainController',
                        controllerAs: 'vm'
                    },
                    'mainContent@main': {
                        templateUrl: 'layouts/main/mainContent.html',
                        controller: 'MainContentController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            });
    }
})();
