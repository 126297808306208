(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('wmsModule')
        .constant('APPID', "56-WMS")
        .constant('VERSION', "1.1.0")
        .constant('DEBUG_INFO_ENABLED', false)
;
})();
