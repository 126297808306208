(function() {
    'use strict';

    angular
        .module('wmsModule')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                authorize: ['SSOAuth','$location', '$localStorage', '$window',
                    function (SSOAuth, $location, $localStorage, $window) {
                        var queryParams = $location.search();
                        if (queryParams && queryParams.token) {
                            $localStorage.authenticationToken = queryParams.token;
                        }

                        return SSOAuth.authorize({redirectUrl: $window.location.href});
                    }
                ],
                enums: ['enumService', '$state', function(enumService, $state) {
                    return enumService.init();
                }],
                moduleId: function() {
                    return "56-WMS";
                }
            }
        });
    }
})();
