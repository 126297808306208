(function() {
    'use strict';

    angular.module('wmsModule')
        .controller('MainContentController', MainContentController);

    MainContentController.$inject = ['$rootScope', 'enums','MainWorkloadStat', '$window','iServiceConfig', '$sce','$http','$timeout','$localStorage','Principal','$interval'];

    function MainContentController ($rootScope, enums,MainWorkloadStat, $window,iServiceConfig,$sce,$http,$timeout,$localStorage,Principal,$interval) {
        var vm = this;
        vm.recDay = 7;
        vm.shipDay = 7;
        vm.workLoadDay = 6;
        vm.workLoadItemDay = 14;
        vm.receiptChart = null;
        vm.soStatus = enums('ship.SOStatus');
        vm.createReceiptChart = createReceiptChart;
        vm.shipChart = null;
        vm.createShipChart = createShipChart;
        vm.selectoptions = [
                            {value:7,label:"近 7 天"},{value:15,label:"近 15 天"},{value:30,label:"近 30 天"}
                            ];
        vm.workloadSelectoptions = [
        	{value:0,label:"当天"},{value:2,label:"近 3 天"},{value:6,label:"近 7 天"},{value:14,label:"近 15 天"}
            ];
        vm.workloadItemSelectoptions = [
        	{value:0,label:"当天"},{value:1,label:"近 2 天"},{value:2,label:"近 3 天"},{value:3,label:"近  4 天"},{value:4,label:"近 5 天"},{value:5,label:"近 6 天"},{value:6,label:"近 7 天"},{value:14,label:"近 15 天"}
            ];
        vm.qtyStat = {
        				inventoryStat:{invQty:0,skuCount:0},
	        			currentRecShipStat:{recExpQty:0,recActQty:0,shipExpQty:0,shipActQty:0},
	        			useRateStat:{volumeTotal:0,volumeUsed:0,locTotal:0,locUsed:0},
	        			monthRecShipStat:{recActQty:0,shipActQty:0}
	        		 };
        vm.recStat = {keyList:[],expQtyList:[],actQtyList:[]};
        vm.shipStat = {keyList:[],expQtyList:[],actQtyList:[]};
        
        vm.workloadStat = {keyList:[],valueGroupObj:{}};
        vm.workloadItemStat = {keyList:[],valueGroupObj:{}};
        
        vm.shipOrders = [];
        vm.recOrders = [];
        
        vm.refreshStat = loadData;
        vm.refreshRecStat = refreshRecStat;
        vm.refreshShipStat = refreshShipStat;
        vm.refreshRecOrder = refreshRecOrder;
        vm.refreshShipOrder = refreshShipOrder;
        vm.refreshWorkloadStat = refreshWorkloadStat;
        vm.refreshWorkloadItemStat = refreshWorkloadItemStat;
        
        vm.account = {};
        Principal.identity().then(function(account) {
            vm.account = account;
            if (vm.account && !vm.account.adminTenant && vm.account.adminTenant != true){
            	loadAllData();
    	    }
        });
        
        function loadData() {
        	$timeout(function() {
                var url = iServiceConfig.idashboardHost + '/web/v1/index/stat/qty-stat?callback=JSON_CALLBACK&token=' + $localStorage.authenticationToken;
                $sce.trustAsResourceUrl(url);
                $http.jsonp(url).success(function (data) {
                    if (data.status === 'SUCCESS') {
                        if (angular.isDefined(data.content)) {
                        	vm.qtyStat = data.content;
                        }
                    }
                }, 0);
        	});
        };
        function refreshWorkloadItemStat() {
        	$timeout(function() {
        		
        		var currentDate = new Date();
        		currentDate.setHours(0);	//设置 Date 对象中的小时 (0 ~ 23)。
        		currentDate.setMinutes(0);	//设置 Date 对象中的分钟 (0 ~ 59)。
        		currentDate.setSeconds(0);	//设置 Date 对象中的秒钟 (0 ~ 59)。
        		currentDate.setMilliseconds(0);
        		
        		if (vm.workLoadItemDay == 0) {
        			var opDateStart = currentDate;
        		} else {
        			var d = new Date();
        			d.setTime(currentDate.getTime() - ((24 * 60 * 60 * 1000) * vm.workLoadItemDay));
        			var opDateStart = d;
        		}
        		var postParams = angular.merge({
					pn : 1,
					ps : 10000
				}, {opDateStart: opDateStart});
        		
        		MainWorkloadStat.list({}, postParams, function(resp) {
        			
        			if (resp.content && resp.content.datas && resp.content.datas.length > 0) {
        				var keyList = [];
        				var opNameMap = {};
        				
        				angular.forEach(resp.content.datas,function (item,index,array) {
        					if (!opNameMap[item.operatorName]) {
        						opNameMap[item.operatorName] = 1;
        					}
        				});
        				
        				for (var opname in opNameMap) {
        					keyList.push(opname);
        				}
        				
        				var dataMap = [];
        				angular.forEach(resp.content.datas,function (item,index,array) {
        					
        					if (!dataMap["收货量"]) {
        						dataMap["收货量"] = {};
        					}
        					if (!dataMap["上架量"]) {
        						dataMap["上架量"] = {};
        					}
        					if (!dataMap["拣选量"]) {
        						dataMap["拣选量"] = {};
        					}
        					if (!dataMap["包装复核量"]) {
        						dataMap["包装复核量"] = {};
        					}
        					if (!dataMap["发货量"]) {
        						dataMap["发货量"] = {};
        					}
        					if (!dataMap["移动量"]) {
        						dataMap["移动量"] = {};
        					}
        					if (!dataMap["盘点量"]) {
        						dataMap["盘点量"] = {};
        					}
        					
        					if (!dataMap["收货量"][item.operatorName]) {
        						dataMap["收货量"][item.operatorName] = 0;
        					}
        					if (!dataMap["上架量"][item.operatorName]) {
        						dataMap["上架量"][item.operatorName] = 0;
        					}
        					if (!dataMap["拣选量"][item.operatorName]) {
        						dataMap["拣选量"][item.operatorName] = 0;
        					}
        					if (!dataMap["包装复核量"][item.operatorName]) {
        						dataMap["包装复核量"][item.operatorName] = 0;
        					}
        					if (!dataMap["发货量"][item.operatorName]) {
        						dataMap["发货量"][item.operatorName]  = 0;
        					}
        					if (!dataMap["移动量"][item.operatorName]) {
        						dataMap["移动量"][item.operatorName] = 0;
        					}
        					if (!dataMap["盘点量"][item.operatorName]) {
        						dataMap["盘点量"][item.operatorName] = 0;
        					}
        					
    						dataMap["收货量"][item.operatorName] = dataMap["收货量"][item.operatorName] + ( item.receiveSkuQty ? item.receiveSkuQty : 0);
    						dataMap["上架量"][item.operatorName] = dataMap["上架量"][item.operatorName] + ( item.putawaySkuQty ? item.putawaySkuQty : 0);
    						dataMap["拣选量"][item.operatorName] = dataMap["拣选量"][item.operatorName] + ( item.pickSkuQty ? item.pickSkuQty : 0);
    						dataMap["包装复核量"][item.operatorName] = dataMap["包装复核量"][item.operatorName] + ( item.packSkuQty ? item.packSkuQty : 0);
    						dataMap["发货量"][item.operatorName]  = dataMap["发货量"][item.operatorName] + ( item.shipSkuQty ? item.shipSkuQty : 0);
    						dataMap["移动量"][item.operatorName] = dataMap["移动量"][item.operatorName] + ( item.moveSkuQty ? item.moveSkuQty : 0);
    						dataMap["盘点量"][item.operatorName] = dataMap["盘点量"][item.operatorName] + ( item.countSkuQty ? item.countSkuQty : 0);
        				});
        				
        				var valueGroupObj = {};
        				angular.forEach(keyList,function (key,index,array) {
        					for (var optype in dataMap) {
        						if (!valueGroupObj[optype]) {
        							valueGroupObj[optype] = [] ;
        						}
        						if (!dataMap[optype][key]) {
        							dataMap[optype][key] = 0;
        						}
        						valueGroupObj[optype].push(dataMap[optype][key]);
        					}
        				});
        				
        				vm.workloadItemStat = {keyList:keyList,valueGroupObj:valueGroupObj};
        			} else {
        				vm.workloadItemStat = {keyList:[],valueGroupObj:[]};
        			}
        			createWorkloadItemChart('dashboard-workload-item-chart',"工作量 - 单项");
				});
        	});
        }
        function refreshWorkloadStat() {
        	$timeout(function() {
        		
        		var currentDate = new Date();
        		currentDate.setHours(0);	//设置 Date 对象中的小时 (0 ~ 23)。
        		currentDate.setMinutes(0);	//设置 Date 对象中的分钟 (0 ~ 59)。
        		currentDate.setSeconds(0);	//设置 Date 对象中的秒钟 (0 ~ 59)。
        		currentDate.setMilliseconds(0);
        		
        		if (vm.workLoadDay == 0) {
        			var opDateStart = currentDate;
        		} else {
        			var d = new Date();
        			d.setTime(currentDate.getTime() - ((24 * 60 * 60 * 1000) * vm.workLoadDay));
        			var opDateStart = d;
        		}
        		var postParams = angular.merge({
					pn : 1,
					ps : 10000
				}, {opDateStart: opDateStart});
        		
        		MainWorkloadStat.list({}, postParams, function(resp) {
        			
        			if (resp.content && resp.content.datas && resp.content.datas.length > 0) {
        				var keyList = [];
        				if (vm.workLoadDay == 0) {
        					keyList.push(currentDate.getFullYear()+"-"+(currentDate.getMonth() + 1)+"-"+currentDate.getDate());
                		} else {
                			for (var nn = vm.workLoadDay; nn >= 0; nn--) {
                				var dd = new Date();
                				dd.setTime(currentDate.getTime() - ((24 * 60 * 60 * 1000) * (nn)));
                				keyList.push(dd.getFullYear()+"-"+(dd.getMonth() + 1)+"-"+dd.getDate());
                			}
                		}
        				var opNameMap = {};
        				angular.forEach(resp.content.datas,function (item,index,array) {
        					if (!opNameMap[item.operatorName]) {
        						opNameMap[item.operatorName] = 1;
        					}
        				});
        				var dataMap = [];
        				angular.forEach(resp.content.datas,function (item,index,array) {
        					var opDate = new Date(item.opDate);
        					var key = opDate.getFullYear()+"-"+(opDate.getMonth() + 1)+"-"+opDate.getDate();
        					
        					if (!dataMap[item.operatorName]) {
        						dataMap[item.operatorName] = {};
        					}
        					if (!dataMap[item.operatorName][key]) {
        						dataMap[item.operatorName][key] = 0;
        					}
        					dataMap[item.operatorName][key] = dataMap[item.operatorName][key] + ( item.countSkuQty ? item.countSkuQty : 0);
        					dataMap[item.operatorName][key] = dataMap[item.operatorName][key] + ( item.moveSkuQty ? item.moveSkuQty : 0);
        					dataMap[item.operatorName][key] = dataMap[item.operatorName][key] + ( item.packSkuQty ? item.packSkuQty : 0);
        					dataMap[item.operatorName][key] = dataMap[item.operatorName][key] + ( item.pickSkuQty ? item.pickSkuQty : 0);
        					dataMap[item.operatorName][key] = dataMap[item.operatorName][key] + ( item.putawaySkuQty ? item.putawaySkuQty : 0);
        					dataMap[item.operatorName][key] = dataMap[item.operatorName][key] + ( item.receiveSkuQty ? item.receiveSkuQty : 0);
        					dataMap[item.operatorName][key] = dataMap[item.operatorName][key] + ( item.shipSkuQty ? item.shipSkuQty : 0);
        				});
        				
        				var valueGroupObj = {};
        				angular.forEach(keyList,function (key,index,array) {
        					for (var opname in opNameMap) {
        						if (!valueGroupObj[opname]) {
        							valueGroupObj[opname] = [] ;
        						}
        						if (!dataMap[opname][key]) {
        							dataMap[opname][key] = 0;
        						}
        						valueGroupObj[opname].push(dataMap[opname][key]);
        					}
        				});
        				
        				vm.workloadStat = {keyList:keyList,valueGroupObj:valueGroupObj};
        			} else {
        				vm.workloadStat = {keyList:[],valueGroupObj:[]};
        			}
        			createWorkloadChart('dashboard-workload-chart',"工作量总览");
				});
        	});
        }
        function refreshRecStat() {
        	$timeout(function() {
                var url = iServiceConfig.idashboardHost + '/web/v1/index/stat/rec-stat/'+vm.recDay+'?callback=JSON_CALLBACK&token=' + $localStorage.authenticationToken;
                $sce.trustAsResourceUrl(url);
                $http.jsonp(url).success(function (data) {
                    if (data.status === 'SUCCESS') {
                        if (angular.isDefined(data.content)) {
                        	vm.recStat = data.content;
                        	createReceiptChart('dashboard-receipt-chart',"收货入库");
                        }
                    }
                }, 0);
        	});
        }
        
        function refreshShipStat() {
        	$timeout(function() {
                var url = iServiceConfig.idashboardHost + '/web/v1/index/stat/ship-stat/'+vm.shipDay+'?callback=JSON_CALLBACK&token=' + $localStorage.authenticationToken;
                $sce.trustAsResourceUrl(url);
                $http.jsonp(url).success(function (data) {
                    if (data.status === 'SUCCESS') {
                        if (angular.isDefined(data.content)) {
                        	vm.shipStat = data.content;
                        	createShipChart('dashboard-ship-chart',"发货出库");
                        }
                    }
                }, 0);
        	});
        }
        function refreshRecOrder() {
        	$timeout(function() {
                var url = iServiceConfig.iwhReceiptHost + '/web/asn/recently?callback=JSON_CALLBACK&token=' + $localStorage.authenticationToken;
                $sce.trustAsResourceUrl(url);
                $http.jsonp(url).success(function (data) {
                    if (data.status === 'SUCCESS') {
                        if (angular.isDefined(data.content)) {
                        	vm.recOrders = data.content;
                        }
                    }
                }, 0);
        	});
        }
        function refreshShipOrder() {
        	$timeout(function() {
                var url = iServiceConfig.iwhShipHost + '/web/so/recently?callback=JSON_CALLBACK&token=' + $localStorage.authenticationToken;
                $sce.trustAsResourceUrl(url);
                $http.jsonp(url).success(function (data) {
                    if (data.status === 'SUCCESS') {
                        if (angular.isDefined(data.content)) {
                        	vm.shipOrders = data.content;
                        }
                    }
                }, 0);
        	});
        }
        function createReceiptChart(div, name) {
        	vm.receiptChart = echarts.getInstanceByDom(document.getElementById(div));
            if(!vm.receiptChart){
            	vm.receiptChart = echarts.init(document.getElementById(div));
            }
            var option = {
            	    title: {
            	        text: name
            	    },
            	    tooltip: {
            	        trigger: 'axis',
            	        axisPointer: {
            	            type: 'shadow'
            	        }
            	    },
            	    toolbox: {
            	    	show: true,
            	        left: 'right',
            	        feature: {
            	            dataView: {show: true, readOnly: false},
            	            magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
            	            restore: {show: true},
            	            saveAsImage: {show: true}
            	        }
            	    },
            	    legend: {
            	        data:['待收货','已收货']
            	    },
            	    grid: {
            	        left: '3%',
            	        right: '4%',
            	        bottom: '3%',
            	        containLabel: true
            	    },
            	    xAxis: {
            	        type: 'category',
            	        boundaryGap: true,
            	        data: vm.recStat.keyList
            	    },
            	    yAxis: {
            	        type: 'value'
            	    },
            	    series: [
            	        {
            	            name:'待收货',
            	            type:'bar',
            	            data:vm.recStat.expQtyList
            	        },
            	        {
            	            name:'已收货',
            	            type:'bar',
            	            data:vm.recStat.actQtyList
            	        }
            	    ]
            	};

            vm.receiptChart.setOption(option);
        };
        function createWorkloadItemChart(div, name) {
        	vm.workloadItemChart = echarts.getInstanceByDom(document.getElementById(div));
            if(!vm.workloadItemChart){
            	vm.workloadItemChart = echarts.init(document.getElementById(div));
            }
            var legendData = [];
            var seriesData = [];
            for (var optype in vm.workloadItemStat.valueGroupObj) {
            	legendData.push(optype);
            	seriesData.push({
            		name:optype,
    	            type:'bar',
    	            data:vm.workloadItemStat.valueGroupObj[optype]
            	});
            }
            
            var option = {
            	    title: {
            	        text: name
            	    },
            	    tooltip: {
            	        trigger: 'axis',
            	        axisPointer: {
            	            type: 'shadow'
            	        }
            	    },
            	    toolbox: {
            	    	show: true,
            	        left: 'right',
            	        feature: {
            	            dataView: {show: true, readOnly: false},
            	            magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
            	            restore: {show: true},
            	            saveAsImage: {show: true}
            	        }
            	    },
            	    legend: {
            	        data:legendData
            	    },
            	    grid: {
            	        left: '3%',
            	        right: '4%',
            	        bottom: '3%',
            	        containLabel: true
            	    },
            	    xAxis: {
            	        type: 'category',
            	        boundaryGap: true,
            	        data: vm.workloadItemStat.keyList
            	    },
            	    yAxis: {
            	        type: 'value'
            	    },
            	    series: seriesData
            	};

            vm.workloadItemChart.setOption(option);
        };
        function createWorkloadChart(div, name) {
        	vm.workloadChart = echarts.getInstanceByDom(document.getElementById(div));
            if(!vm.workloadChart){
            	vm.workloadChart = echarts.init(document.getElementById(div));
            }
            var legendData = [];
            var seriesData = [];
            for (var opname in vm.workloadStat.valueGroupObj) {
            	legendData.push(opname);
            	seriesData.push({
            		name:opname,
    	            type:'bar',
    	            data:vm.workloadStat.valueGroupObj[opname]
            	});
            }
            
            var option = {
            	    title: {
            	        text: name
            	    },
            	    tooltip: {
            	        trigger: 'axis',
            	        axisPointer: {
            	            type: 'shadow'
            	        }
            	    },
            	    toolbox: {
            	    	show: true,
            	        left: 'right',
            	        feature: {
            	            dataView: {show: true, readOnly: false},
            	            magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
            	            restore: {show: true},
            	            saveAsImage: {show: true}
            	        }
            	    },
            	    legend: {
            	        data:legendData
            	    },
            	    grid: {
            	        left: '3%',
            	        right: '4%',
            	        bottom: '3%',
            	        containLabel: true
            	    },
            	    xAxis: {
            	        type: 'category',
            	        boundaryGap: true,
            	        data: vm.workloadStat.keyList
            	    },
            	    yAxis: {
            	        type: 'value'
            	    },
            	    series: seriesData
            	};

            vm.workloadChart.setOption(option);
        };
        
        function createShipChart(div, name) {
        	vm.shipChart = echarts.getInstanceByDom(document.getElementById(div));
            if(!vm.shipChart){
            	vm.shipChart = echarts.init(document.getElementById(div));
            }
            var option = {
                    title: {
                        text: name
                    },
                    tooltip: {
                        trigger: 'axis',
            	        axisPointer: {
            	            type: 'shadow'
            	        }
                    },
                    legend: {
                        data:['待发货','已发货']
                    },
            	    toolbox: {
            	    	show: true,
            	        left: 'right',
            	        feature: {
            	            dataView: {show: true, readOnly: false},
            	            magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
            	            restore: {show: true},
            	            saveAsImage: {show: true}
            	        }
            	    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
            	        type: 'category',
            	        boundaryGap: true,
            	        data: vm.shipStat.keyList
            	        //['周一','周二','周三','周四','周五','周六','周日']
            	    },
            	    yAxis: {
            	        type: 'value'
            	    },
            	    series: [
            	        {
            	            name:'待发货',
            	            type:'bar',
            	            data:vm.shipStat.expQtyList
            	        },
            	        {
            	            name:'已发货',
            	            type:'bar',
            	            data:vm.shipStat.actQtyList
            	        }
            	    ]
                };

            vm.shipChart.setOption(option);
        };
        
        $window.addEventListener("resize", function(){
        	if (vm.shipChart) {
        		vm.shipChart.resize();
        	}
        	if (vm.receiptChart) {
        		vm.receiptChart.resize();
        	}
        	if (vm.workloadChart) {
        		vm.workloadChart.resize();
        	}
        	if (vm.workloadItemChart) {
        		vm.workloadItemChart.resize();
        	}
        });
        $window.onload=function() {
        	if (vm.shipChart) {
        		vm.shipChart.resize();
        	}
        	if (vm.receiptChart) {
        		vm.receiptChart.resize();
        	}
        	if (vm.workloadChart) {
        		vm.workloadChart.resize();
        	}
        	if (vm.workloadItemChart) {
        		vm.workloadItemChart.resize();
        	}
        };
        function loadAllData() {
        	vm.refreshStat();
	        vm.refreshRecStat();
	        vm.refreshShipStat();
	        vm.refreshRecOrder();
	        vm.refreshShipOrder();
	        vm.refreshWorkloadStat();
	        vm.refreshWorkloadItemStat();
        }
        vm.dashboardchartwidth = 0;
        var timer = $interval(function () {
        	var o = document.getElementById("dashboard-chart-row");
        	if (o) {
        		var w = o.clientWidth || o.offsetWidth;
            	if (vm.dashboardchartwidth != w) {
            		vm.dashboardchartwidth = w;
            		if (vm.shipChart) {
                		vm.shipChart.resize();
                	}
                	if (vm.receiptChart) {
                		vm.receiptChart.resize();
                	}
                	if (vm.workloadChart) {
                		vm.workloadChart.resize();
                	}
                	if (vm.workloadItemChart) {
                		vm.workloadItemChart.resize();
                	}
            	}
        	}
        },2000);
    }
})();
