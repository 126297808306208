(function() {
    'use strict';

    angular
        .module('wmsModule', [
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'angularMoment',
            'ui.router',
            'infinite-scroll',
            'ngTouch',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ngTable',
            'exTable',
            'filesModel',
            'ui.tree',
            'treeControl',
            'ngTree',
            'ngSelect',
            'ngMenu',
            'toastr',
            'iservice.base',
            'scExportBtn',
            'frapontillo.bootstrap-switch',
            // module
            'iuserModule',
            'idataModule',
            'idashboardModule',
            'imessageModule',
            'iplanModule',
            'ienterpriseModule',
            'doveModule',
            'ireportModule',
            'ireportNodeModule',
            'iprintModule',
            'iergateModule',
            'iportalModule',
            'itaskModule',
            'iwh-dataModule',
            'iwh-strategyModule',
            'iwh-innerModule',
            'iwh-receiptModule',
            'iwh-shipModule',
            'iwh-inventoryModule',
            'itms-orderModule',
            'itms-dataModule',
            'ivendorModule'
        ])
        .run(run);

    run.$inject = ['stateHandler', '$rootScope'];

    function run(stateHandler, $rootScope) {
        stateHandler.initialize();
    }
})();
