(function() {
    'use strict';

    angular.module('wmsModule')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['iServiceConfig', 'Principal', '$state'];

    function NavbarController(iServiceConfig, Principal, $state) {
        var vm = this;
        vm.systemMenuHost = iServiceConfig.systemMenuHost;
        vm.logout = logout;
        vm.showNavbar = !$state.params.showNavbar || $state.params.showNavbar == 'Y'
        Principal.identity().then(function(data) {
            vm.account = data;
        });

        vm.systemMenuHost = iServiceConfig.systemMenuHost;
        vm.shortcutMenuHost = iServiceConfig.shortcutMenuHost;
        vm.shortMenuHost = iServiceConfig.shortMenuHost;
        vm.userPendingHost = iServiceConfig.iportalPendingHost;

        function logout() {
            location.reload(true);
        }
    }
})();

