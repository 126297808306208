(function (window) {
    window.__env = window.__env || {};

    window.__env.appid= 'iservice';

    window.__env.warehouseRequiredAppList= 'iwh-strategy,iwh-receipt,iwh-inventory,iwh-ship,iwh-data,iwh-inner';
    window.__env.warehouseSelectUrl= 'http://sso.56start.com/#/main/select-warehouse';
    window.__env.systemMenuHost= 'http://gate.56start.com/iuser/web/user/menus?callback=JSON_CALLBACK&token=';
    window.__env.shortcutMenuHost= 'http://gate.56start.com/iuser/web/user/shortcutMenu?callback=JSON_CALLBACK&token=';
    window.__env.shortMenuHost= 'http://gate.56start.com/iuser/web/user/shortMenu?callback=JSON_CALLBACK&token=';
    window.__env.logoutHost= 'http://gate.56start.com/isso/api/logout?callback=JSON_CALLBACK&token=';
    window.__env.updatePasswordHost= 'http://gate.56start.com/iuser/web/user/updatePassword?token=';
    window.__env.iportalPendingHost= 'http://gate.56start.com/iportal/web/user/pending?callback=JSON_CALLBACK&token=';
    window.__env.favmenuHost= 'http://56start.com/#/user/favmenu-maintenance?token=';
    window.__env.itmsDataHost= 'http://gate.56start.com/itmsd';
    window.__env.itmsOrderHost= 'http://gate.56start.com/itmso';
    window.__env.iwhShipHost= 'http://gate.56start.com/iwh-ship';
    window.__env.iwhStrategyHost= 'http://gate.56start.com/iwh-strategy';
    window.__env.iwhReceiptHost= 'http://gate.56start.com/iwh-receipt';
    window.__env.iwhDataHost= 'http://gate.56start.com/iwh-data';
    window.__env.iwhInventoryHost= 'http://gate.56start.com/iwh-inventory';
    window.__env.idataHost= 'http://gate.56start.com/idata';
    window.__env.idashboardHost= 'http://gate.56start.com/idashboard';
    window.__env.iuserHost= 'http://gate.56start.com/iuser';
    window.__env.issoHost= 'http://gate.56start.com/isso';
    window.__env.iplanHost= 'http://gate.56start.com/iplan';
    window.__env.ibusHost= 'http://gate.56start.com/ibus';
    window.__env.iergateHost= 'http://gate.56start.com/iergate';
    window.__env.ienterpriseHost= 'http://gate.56start.com/ienterprise';
    window.__env.imessageHost= 'http://gate.56start.com/imessage';
    window.__env.iwhInnerHost= 'http://gate.56start.com/iwh-inner';
    window.__env.iwhAppHost= 'http://gate.56start.com/iwh-app';
    window.__env.ireportHost= 'http://gate.56start.com/ireport';
    window.__env.ireportNodeHost= 'http://gate.56start.com/ireport-node';
    window.__env.iprintHost= 'http://gate.56start.com/iprint';
    window.__env.iportalHost= 'http://gate.56start.com/iportal';
    window.__env.itaskHost= 'http://gate.56start.com/itask';
    window.__env.vendorHost= 'http://gate.56start.com/ivendor';
    window.__env.iappHost= 'http://gate.56start.com/iapp';
    window.__env.frontHost= 'http://127.0.0.1:8000';
    window.__env.issoFrontHost= "http://sso.56start.com";
    window.__env.iappFrontHost= "http://app.56start.com";
    window.__env.iwhAppFrontHost= "http://wapp.56start.com";

    window.__env.httpTimeout= 120000;
    window.__env.consoleDebug= false;
}(this));