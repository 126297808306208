(function() {
    'use strict';

    angular.module('wmsModule')
        .controller('MainController', MainController);

    MainController.$inject = ['$rootScope', '$window','iServiceConfig', '$sce','$http','$timeout','$localStorage','$state'];
    
    function MainController ($rootScope, $window,iServiceConfig,$sce,$http,$timeout,$localStorage,$state) {
        var vm = this;
        vm.state = $rootScope;
        vm.user = {};
        vm.errors = {};
        vm.pageTitle = pageTitle;
        vm.loadFavMenu = loadFavMenu;
        vm.data=[];
        vm.currentAccount = {realName: ''};
        vm.favMenus = [];
        function pageTitle() {
            return $window.document.title;
        }
        
        function loadFavMenu() {
        	$timeout(function() {
                var url = iServiceConfig.shortcutMenuHost + $localStorage.authenticationToken;
                $sce.trustAsResourceUrl(url);
                $http.jsonp(url).success(function (data) {
                    if (data.status === 'SUCCESS') {
                        if (angular.isDefined(data.content) && angular.isArray(data.content) && (data.content.length > 0)) {
                        	vm.favMenus = data.content;
                        }
                    }
                }, 0);
        	});
        };
        vm.showNavbar = !$state.params.showNavbar || $state.params.showNavbar == 'Y'
        if (vm.showNavbar) {
        	vm.loadFavMenu();
        }
    }
})();
